@keyframes glow {
  0% {
    box-shadow: 0 0 #ffffffb3, .25rem .5rem 3.5rem #ffffffb3;
  }

  70% {
    box-shadow: 0 0 0 1rem #fff0, 0 0 #fff0;
  }

  100% {
    box-shadow: 0 0 #fff0, .25rem .5rem 3.5rem #ffffffb3;
  }
}

.header-container01 {
  display: contents;
}

.header-container02 {
  background-image: url("email-alt1.f14330ae.svg");
  background-position: center;
  background-size: cover;
  width: 2rem;
  height: 2rem;
  text-decoration: none;
  transition: all .28s;
  display: flex;
}

.header-container02:hover {
  background-image: url("email.8791aba3.svg");
  transform: scale(1.125);
}

.header-text {
  color: #fff;
  margin-left: 3rem;
  font-size: 1.6rem;
}

.header-container03 {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.header-image {
  object-fit: cover;
  width: 4rem;
  margin-top: .5rem;
  margin-left: -4rem;
  margin-right: -4rem;
  position: absolute;
}

.header-navlink1 {
  display: contents;
}

.timeline__wrap {
  width: 100vw;
  height: auto;
  max-height: 100%;
  margin-left: -3rem;
}

.header-heading {
  color: var(--dl-color-gray-white);
  text-align: center;
  font-family: HVDPoster;
  font-size: 2.5rem;
  font-weight: 400;
  text-decoration: none;
}

.header-burger-menu {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.header-icon {
  fill: #fff;
  flex: auto;
  align-self: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto;
  display: flex;
  position: relative;
  right: -25%;
}

.header-mobile-menu {
  color: #fff;
  padding: var(--dl-space-space-unitandhalf);
  z-index: 6;
  background-color: #000;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  font-family: Raleway;
  font-weight: 600;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.header-nav {
  color: #fff;
  opacity: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.header-container04 {
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.header-navlink2 {
  display: contents;
}

.header-heading1 {
  color: var(--dl-color-gray-white);
  text-align: center;
  margin: 0 auto;
  padding-left: 4rem;
  font-family: HVDPoster;
  font-size: 3rem;
  font-weight: 400;
  text-decoration: none;
}

.header-menu-close {
  justify-content: center;
  align-items: center;
  display: flex;
}

.header-icon2 {
  fill: red;
  width: 4rem;
  height: 4rem;
}

.header-nav1 {
  flex-direction: column;
  flex: none;
  place-items: center;
  width: 100%;
  margin: 0 auto;
  font-size: 1rem;
  display: flex;
}

.header-container05 {
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 2.4rem;
  display: flex;
  position: relative;
}

.header-container06 {
  flex: none;
  align-items: flex-start;
  display: flex;
}

.header-text1 {
  text-align: center;
  text-underline-offset: 1rem;
  text-underline-position: below;
  margin-bottom: 10vh;
  font-size: 1.75rem;
  line-height: 2.65rem;
  text-decoration: underline;
}

.header-container07 {
  flex: none;
  align-items: flex-start;
  display: flex;
}

.header-text2 {
  text-align: left;
  margin-bottom: 10vh;
  font-size: 1.75rem;
}

.header-container08 {
  flex: none;
  align-items: flex-end;
  display: flex;
}

.header-text3 {
  text-align: left;
  margin-bottom: 10vh;
  font-size: 1.75rem;
}

.header-icon-group {
  object-position: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  display: flex;
}

.header-row {
  fill: #f70;
  display: inline-block;
}

.header-icon4 {
  fill: #f70;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  text-decoration: none;
}

.header-row1 {
  fill: #d25d6f;
  display: inline-block;
}

.header-icon6 {
  fill: #d25d6f;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  text-decoration: none;
}

.header-row2 {
  fill: #d25d6f;
  display: inline-block;
}

.header-image1 {
  fill: #d25d6f;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  text-decoration: none;
}

.header-row3 {
  fill: #1d9bf0;
  display: inline-block;
}

.header-image2 {
  fill: #1d9bf0;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  text-decoration: none;
}

.header-row4 {
  fill: red;
  display: inline-block;
}

.header-image3 {
  fill: red;
  width: 1.84rem;
  height: 1.84rem;
  text-decoration: none;
  position: relative;
  top: .12rem;
}

.header-container09 {
  flex-direction: row;
  flex: none;
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
}

.header-container10 {
  flex-direction: row;
  align-items: flex-start;
  transition: all .3s;
  display: flex;
}

.header-container10:hover {
  transform: scale(1.125);
}

.header-container11 {
  border-radius: var(--dl-radius-radius-round);
  background: #fff;
  flex-direction: row;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: .5rem;
  margin-right: .875rem;
  display: flex;
  box-shadow: .25rem .25rem .5rem #fffc;
}

.header-link {
  display: contents;
}

.header-image4 {
  fill: #000;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  text-decoration: none;
}

.header-root-class-name {
  margin-bottom: 0;
}

.header-root-class-name1 {
  min-width: 100%;
  height: 3.75rem;
  border: 0 !important;
}

.header-root-class-name2 {
  max-width: 100%;
  height: 3.75rem;
}

@media (width <= 1200px) {
  .header-container05 {
    flex-direction: row;
    width: auto;
  }

  .header-text1, .header-text2, .header-text3 {
    margin-right: 10vw;
  }
}

@media (width <= 992px) {
  .header-image {
    width: 2.75rem;
    height: 2.75rem;
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
}

@media (width <= 991px) {
  .header-container05 {
    flex-direction: row;
  }
}

@media (width <= 784px) {
  .header-container02 {
    width: 1.6325rem;
    height: 1.6325rem;
    animation-name: fadeOutUp;
    animation-duration: 1.17s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-direction: reverse;
    animation-delay: 0s;
    transform: scale(1.025);
  }

  .header-text {
    margin-left: 2.5rem;
    font-size: 1.5rem;
    animation-name: fadeOutUp;
    animation-duration: 1.17s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-direction: reverse;
    animation-delay: 0s;
  }

  .header-container03 {
    padding-left: 19vw;
  }

  .header-image {
    animation-name: fadeOutUp;
    animation-duration: 1.17s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-direction: reverse;
    animation-delay: 0s;
    display: none;
  }

  .header-heading {
    font-size: 2rem;
    animation-name: fadeOutUp;
    animation-duration: 1.17s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-direction: reverse;
    animation-delay: 0s;
  }

  .header-heading1 {
    font-size: 2.75rem;
    animation-name: fadeOutUp;
    animation-duration: 1.17s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-direction: reverse;
    animation-delay: 0s;
  }

  .header-text1 {
    font-size: 1.75rem;
    line-height: 2.65rem;
    text-decoration: underline;
  }

  .header-image4 {
    margin-top: 0;
    margin-bottom: 0;
    animation-name: fadeOutUp;
    animation-duration: 1.17s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-direction: reverse;
    animation-delay: 0s;
    box-shadow: 0 4px 6px #0000001a, 0 1px 3px #00000014, 0 0 0 1px #0000000d;
  }

  .header-root-class-name1 {
    height: 3.75rem;
  }
}

@media (width <= 767px) {
  .header-container05 {
    flex-direction: column;
  }

  .header-text1, .header-text2, .header-text3 {
    margin-right: 0;
  }
}

@media (width <= 540px) {
  .header-container02 {
    width: 1.5rem;
    height: 1.5rem;
  }

  .header-text {
    margin-left: 2rem;
    font-size: 1.275rem;
    font-weight: 600;
  }

  .header-image {
    display: none;
  }

  .header-heading {
    margin-left: 2rem;
    font-size: 1.75rem;
  }

  .header-burger-menu {
    display: flex;
  }

  .header-icon {
    margin-right: 25%;
  }

  .header-heading1 {
    font-size: 2.75rem;
  }

  .header-container09 {
    display: none;
  }

  .header-root-class-name {
    margin-bottom: 0;
  }
}

@media (width <= 479px) {
  .header-container02 {
    width: 1rem;
    height: 1rem;
  }

  .header-text {
    margin-left: 1.25rem;
    font-size: 1rem;
  }

  .header-heading {
    font-size: 1.5rem;
    display: none;
  }

  .header-mobile-menu {
    height: 100vh;
  }

  .header-heading1 {
    font-size: 2rem;
    display: none;
  }

  .header-menu-close {
    width: 100%;
  }

  .header-container05 {
    flex-direction: column;
  }

  .header-text1, .header-text2, .header-text3 {
    margin-right: 0;
  }
}

.footer-container {
  padding: var(--dl-space-space-doubleunit);
  background: linear-gradient(330deg, #a1a1a1, #9f9f9f, #9b9b9b, #949494, #8c8c8c, #828282, #797979, #707070, #686868, #626262, #5e5e5e, #5c5c5c);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  max-width: 100vw;
  display: flex;
}

.footer-container1 {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.footer-container2 {
  flex-direction: column;
  flex: none;
  align-items: center;
  display: flex;
}

.footer-text {
  color: var(--dl-color-pimary-900);
  text-align: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  font-family: Raleway;
  font-size: 2rem;
  font-weight: 600;
  text-decoration: underline;
}

.footer-text1 {
  color: var(--dl-color-gray-white);
  word-wrap: word-break;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  text-shadow: 0 .2rem .15rem #000000b3, 0 .4rem .65rem #0003, 0 .9rem 1.15rem #0003;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: uppercase;
  font-family: HVDPoster;
  font-size: 2rem;
}

.footer-container3 {
  margin-top: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-tripleunit);
  background-color: var(--dl-color-pimary-900);
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 100%;
  height: 1px;
  display: flex;
  box-shadow: 0 1rem 1.5rem -.25rem #0000001a, 0 .5rem .5rem -.25rem #0000000a;
}

.footer-text5 {
  color: var(--dl-color-gray-white);
  text-align: center;
  text-shadow: 0 .2rem .15rem #000000b3, 0 .4rem .65rem #0003, 0 .9rem 1.15rem #0003;
  letter-spacing: .25rem;
  align-self: center;
  font-family: Raleway;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.925rem;
}

.footer-container {
  padding-top: 8rem;
}

@media (width <= 1363px) {
  .footer-container2 {
    flex: 0 auto;
  }

  .footer-root-class-name {
    width: 100%;
  }
}

@media (width <= 992px) {
  .footer-container2 {
    flex: 0 auto;
  }
}

@media (width <= 784px) {
  .footer-container1 {
    flex-direction: column;
    align-items: center;
  }

  .footer-container2 {
    margin-bottom: var(--dl-space-space-doubleunit);
    align-items: center;
  }
}

.home-container {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  min-height: 100vh;
  font-family: Raleway;
  display: flex;
}

.home-hero {
  background: #000;
  flex-direction: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
}

.home-bg {
  opacity: .7;
  background-color: var(--dl-color-gray-black);
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.home-container1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  inset: auto 0 0 auto;
}

.home-container2 {
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.home-text {
  color: var(--dl-color-gray-white);
  text-align: center;
}

.home-text1 {
  color: var(--dl-color-gray-900);
  text-align: center;
  max-width: 600px;
}

.timeline {
  z-index: 3;
  top: 5rem;
}

.timeline__items {
  margin-bottom: 5rem;
}

.timeline__item {
  min-width: 50vw;
  max-width: 100%;
  min-height: 14rem;
  padding: 0 !important;
}

.timeline__content {
  box-sizing: content-box;
  text-align: center;
  text-overflow: ellipsis;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: border-box;
  width: 15rem;
  height: 15rem;
  margin: 0 auto;
  font-size: 1.75rem;
  font-weight: 700;
  animation: 4s infinite glow;
  overflow: hidden;
  color: var(--basiccolor) !important;
  max-width: 75% !important;
  padding: .25rem !important;
}

.home-container3 {
  box-sizing: content-box;
  background: #000 center / cover;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  display: flex;
  box-shadow: 0 1rem 1.5rem -.25rem #0000001a, 0 .5rem .5rem -.25rem #0000000a;
}

.home-image {
  object-fit: cover;
  width: 100%;
  display: none;
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
}

.home-container5 {
  display: contents;
}

@media (width <= 479px) {
  .home-container2 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
/*# sourceMappingURL=index.1bcb18c8.css.map */
