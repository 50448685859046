@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7), .25rem .5rem 3.5rem rgba(255,255,255,0.7);
  }
  70% {
    box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0), 0 0 0 rgba(255,255,255,0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), .25rem .5rem 3.5rem rgba(255,255,255,0.7);
  }
}

.header-container01 {
  display: contents;
}
.header-container02 {
  width: 2rem;
  height: 2rem;
  display: flex;
  transition: 280ms;
  background-size: cover;
  text-decoration: none;
  background-image: url("public/email-alt1.svg");
  background-position: center;
}
.header-container02:hover {
  transform: scale(1.125);
  background-image: url("public/email.svg");
}
.header-text {
  color: #ffffff;
  font-size: 1.6rem;
  margin-left: 3rem;
}
.header-container03 {
  margin: 0 auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-image {
  width: 4rem;
  position: absolute;
  margin-top: .5rem;
  object-fit: cover;
  margin-left: -4rem;
  margin-right: -4rem;
}
.header-navlink1 {
  display: contents;
}
.timeline__wrap {
	height: auto;
	max-height:100%;
	width:100vw;
	margin-left:-3rem;
}
.header-heading {
  color: var(--dl-color-gray-white);
  font-size: 2.5rem;
  text-align: center;
  font-family: "HVDPoster";
  font-weight: 400;
  text-decoration: none;
}
.header-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-icon {
  fill: #FFF;
  flex: auto;
  right: -25%;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto;
  display: flex;
  position: relative;
  align-self: center;
}
.header-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  color: #FFF;
  width: 100vw;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-unitandhalf);
  z-index: 6;
  position: absolute;
  align-items: flex-start;
  font-family: "Raleway";
  font-weight: 600;
  flex-direction: column;
  background-color: black;
}
.header-nav {
  color: #FFF;
  display: flex;
  opacity: 1;
  align-items: flex-start;
  flex-direction: column;
  width:100%;
}
.header-container04 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header-navlink2 {
  display: contents;
}
.header-heading1 {
  color: var(--dl-color-gray-white);
  margin: 0 auto;
  font-size: 3rem;
  text-align: center;
  font-family: "HVDPoster";
  font-weight: 400;
  padding-left: 4rem;
  text-decoration: none;
}
.header-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icon2 {
  fill: red;
  width: 4rem;
  height: 4rem;
}
.header-nav1 {
  flex: 0 0 auto;
  width: 100%;
  margin: 0 auto;
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}
.header-container05 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  font-size: 2.4rem;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.header-container06 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
}
.header-text1 {
  font-size: 1.75rem;
  text-align: center;
  line-height: 2.65rem;
  margin-bottom: 10vh;
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-underline-position: below;
}
.header-container07 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
}
.header-text2 {
  font-size: 1.75rem;
  text-align: left;
  margin-bottom: 10vh;
}
.header-container08 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-end;
}
.header-text3 {
  font-size: 1.75rem;
  text-align: left;
  margin-bottom: 10vh;
}
.header-icon-group {
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
  object-position: center;
  width:100%;
}
.header-row {
  fill: #ff7700;
  display: inline-block;
}
.header-icon4 {
  fill: #ff7700;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  text-decoration: none;
}
.header-row1 {
  fill: #D25D6F;
  display: inline-block;
}
.header-icon6 {
  fill: #D25D6F;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  text-decoration: none;
}
.header-row2 {
  fill: #D25D6F;
  display: inline-block;
}
.header-image1 {
  fill: #D25D6F;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  text-decoration: none;
}
.header-row3 {
  fill: #1D9BF0;
  display: inline-block;
}
.header-image2 {
  fill: #1D9BF0;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  text-decoration: none;
}
.header-row4 {
  fill: #FF0000;
  display: inline-block;
}
.header-image3 {
  top: 0.12rem;
  fill: #FF0000;
  width: 1.84rem;
  height: 1.84rem;
  position: relative;
  text-decoration: none;
}
.header-container09 {
  flex: 0 0 auto;
  right: 0;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
}
.header-container10 {
  display: flex;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: row;
}
.header-container10:hover {
  transform: scale(1.125);
}
.header-container11 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  background: #ffffff;
  box-shadow: 0.25rem 0.25rem 0.5rem 0px rgba(255,255,255,.8);
  align-items: center;
  margin-right: 0.875rem;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: .5rem;
  flex-direction: row;
  justify-content: center;
}
.header-link {
  display: contents;
}
.header-image4 {
  width: 2rem;
  height: 2rem;
  fill: #000;
  /*margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);*/
  margin: 0 auto;
  text-decoration: none;
}
.header-root-class-name {
  margin-bottom: 0px;
}
.header-root-class-name1 {
  border:0 !important;
  min-width: 100%;
  height: 3.75rem;
}
.header-root-class-name2 {
  height: 3.75rem;
  max-width: 100%;
}

@media(max-width: 1200px) {
  .header-container05 {
    width: auto;
    flex-direction: row;
  }
  .header-text1 {
    margin-right: 10vw;
  }
  .header-text2 {
    margin-right: 10vw;
  }
  .header-text3 {
    margin-right: 10vw;
  }
}
@media(max-width: 992px) {
  .header-image {
    width: 2.75rem;
    height: 2.75rem;
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
}
@media(max-width: 991px) {
  .header-container05 {
    flex-direction: row;
  }
}
@media(max-width: 784px) {
  .header-container02 {
    width: 1.6325rem;
    height: 1.6325rem;
    transform: scale(1.025);
    animation-name: fadeOutUp;
    animation-delay: 0s;
    animation-duration: 1170ms;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
  }
  .header-text {
    font-size: 1.5rem;
    margin-left: 2.5rem;
    animation-name: fadeOutUp;
    animation-delay: 0s;
    animation-duration: 1170ms;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
  }
  .header-container03 {
    padding-left: 19vw;
  }
  .header-image {
    display: none;
    animation-name: fadeOutUp;
    animation-delay: 0s;
    animation-duration: 1170ms;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
  }
  .header-heading {
    font-size: 2rem;
    animation-name: fadeOutUp;
    animation-delay: 0s;
    animation-duration: 1170ms;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
  }
  .header-heading1 {
    font-size: 2.75rem;
    animation-name: fadeOutUp;
    animation-delay: 0s;
    animation-duration: 1170ms;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
  }
  .header-text1 {
    font-size: 1.75rem;
    line-height: 2.65rem;
    text-decoration: underline;
  }
  .header-image4 {
    margin-top: 0px;
    box-shadow:  0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.05);
    margin-bottom: 0px;
    animation-name: fadeOutUp;
    animation-delay: 0s;
    animation-duration: 1170ms;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
  }
  .header-root-class-name1 {
    height: 3.75rem;
  }
}
@media(max-width: 767px) {
  .header-container05 {
    flex-direction: column;
  }
  .header-text1 {
    margin-right: 0;
  }
  .header-text2 {
    margin-right: 0;
  }
  .header-text3 {
    margin-right: 0;
  }
}
@media(max-width: 540px) {
  .header-container02 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .header-text {
    font-size: 1.275rem;
    font-weight: 600;
    margin-left: 2rem;
  }
  .header-image {
    display: none;
  }
  .header-heading {
    font-size: 1.75rem;
    margin-left: 2rem;
  }
  .header-burger-menu {
    display: flex;
  }
  .header-icon {
    margin-right: 25%;
  }
  .header-heading1 {
    font-size: 2.75rem;
  }
  .header-container09 {
    display: none;
  }
  .header-root-class-name {
    margin-bottom: 0px;
  }
}
@media(max-width: 479px) {
  .header-container02 {
    width: 1rem;
    height: 1rem;
  }
  .header-text {
    font-size: 1rem;
    margin-left: 1.25rem;
  }
  .header-heading {
    display: none;
    font-size: 1.5rem;
  }
  .header-mobile-menu {
    height: 100vh;
  }
  .header-heading1 {
    display: none;
    font-size: 2rem;
  }
  .header-menu-close {
    width: 100%;
  }
  .header-container05 {
    flex-direction: column;
  }
  .header-text1 {
    margin-right: 0;
  }
  .header-text2 {
    margin-right: 0;
  }
  .header-text3 {
    margin-right: 0;
  }
}

.footer-container {
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  max-width: 100vw;
  min-width: 100%;
  background: linear-gradient(330deg, #a1a1a1, #9f9f9f, #9b9b9b, #949494, #8c8c8c, #828282, #797979, #707070, #686868, #626262, #5e5e5e, #5c5c5c);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.footer-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container2 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer-text {
  color: var(--dl-color-pimary-900);
  font-size: 2rem;
  text-align: center;
  font-family: "Raleway";
  font-weight: 600;
  margin-bottom: var(--dl-space-space-tripleunit);
  text-decoration: underline;
}
.footer-text1 {
  color: var(--dl-color-gray-white);
  font-size: 2rem;
  word-wrap: word-break;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: "HVDPoster";
  text-shadow: 0 .2rem .15rem rgba(0,0,0,0.7), 0 .4rem .65rem rgba(0,0,0,0.2), 0 .9rem 1.15rem rgba(0,0,0,0.2);
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: uppercase;
}
.footer-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  box-shadow: 0 1rem 1.5rem -0.25rem rgba(0,0,0,0.1),0 .5rem .5rem -0.25rem rgba(0,0,0,0.04);
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: var(--dl-color-pimary-900);
}
.footer-text5 {
  color: var(--dl-color-gray-white);
  font-size: 1rem;
  align-self: center;
  text-align: center;
  font-family: "Raleway";
  font-weight: 800;
  line-height: 1.925rem;
  text-shadow: 0 .2rem .15rem rgba(0,0,0,0.7), 0 .4rem .65rem rgba(0,0,0,0.2), 0 .9rem 1.15rem rgba(0,0,0,0.2);
  letter-spacing: .25rem;
}
.footer-container {
    padding-top: 8rem;
}

@media(max-width: 1363px) {
  .footer-container2 {
    flex: 0 auto;
  }
  .footer-root-class-name {
    width: 100%;
  }
}
@media(max-width: 992px) {
  .footer-container2 {
    flex: 0 auto;
  }
}
@media(max-width: 784px) {
  .footer-container1 {
    align-items: center;
    flex-direction: column;
  }
  .footer-container2 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
}

.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  font-family: "Raleway";
  flex-direction: column;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background:#000;
}
.home-bg {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0.7;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.home-container1 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container2 {
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}
.home-text {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.home-text1 {
  color: var(--dl-color-gray-900);
  max-width: 600px;
  text-align: center;
}
.timeline {
	top:5rem;
	z-index:3;
}
.timeline__items {
	margin-bottom:5rem;
}
.timeline__item {
	min-height:14rem;
	min-width:50vw;
	max-width:100%;
	padding:0 !important;
}

.timeline__content {
	box-sizing:content-box;
	max-width:75% !important;
	margin-left:25%;
	padding:.25rem !important;
	text-align: center;
	overflow:hidden;
	text-overflow: ellipsis;
	color: var(--basiccolor) !important;
	width:15rem;
	height:15rem;
	margin: 0 auto;
	background-size:contain;
	background-origin:border-box;
	background-repeat: no-repeat;
	background-position: center;
	animation: 4s infinite glow;
  font-size:1.75rem;
  font-weight:700;
}
.home-container3 {
  width: 100%;
  box-sizing: content-box;
  height: auto;
  display: flex;
  background: #000;
  box-shadow: 0 1rem 1.5rem -0.25rem rgba(0,0,0,0.1),0 .5rem .5rem -0.25rem rgba(0,0,0,0.04);
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
}
.home-image {
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-container5 {
  display: contents;
}
@media(max-width: 479px) {
  .home-container2 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
